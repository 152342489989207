export default {
  data: () => ({
    uploader: ['default'],
    uploaderFiles: {},
    uploaderFilesToSubmit: {},
  }),
  computed: {
    maxFiles () {
      const maxFiles = {};

      this.uploaderInstances.forEach(instance => {
        maxFiles[instance.name] = instance.fixedMaxFiles;
      });

      return maxFiles;
    },
    uploaderInstances () {
      return (this.uploader || ['default']).map(instance => {
        if (Object.prototype.toString.call(instance) === '[object String]') {
          instance = {
            name: instance,
            fixedMaxFiles: 50,
          };
        } else if (Array.isArray(instance)) {
          instance = {
            name: instance[0] || 'default',
            fixedMaxFiles: instance[1] || 50,
          };
        }

        instance = {
          name: instance.name || 'default',
          fixedMaxFiles: instance.fixedMaxFiles || 50,
        };

        return instance;
      });
    },
  },
  methods: {
    resetFiles () {
      this.uploaderInstances.forEach(instance => {
        this.uploaderFiles[instance.name] = [];
        this.uploaderFilesToSubmit[instance.name] = [];
      });
    },

    // On single file
    startUpload (file, type = 'default') {
      return this.$api.uploadFile(file, type);
    },
    removeFile (file, instanceName = 'default') {
      if (file.remove) {
        file.remove();
      }

      this.removeFileFromIndex(file, instanceName);
    },
    removeFileFromIndex (file, instanceName = 'default') {
      this.uploaderFiles[instanceName] = (this.uploaderFiles[instanceName] || []).filter(i => i !== file);
    },
    replaceFile (file, instanceName = 'default') {
      this.removeFile(file);
      this.$refs['uploader-' + instanceName].openFilePicker(file);
    },
    uploadsFinishedAndReadyToSubmit () {
      let finished = true;

      this.uploaderInstances.forEach(instance => {
        finished = this.uploadsFinishedAndReadyToSubmitInInstance(instance.name) && finished;
      });

      return finished;
    },
    uploadsFinishedAndReadyToSubmitInInstance (instanceName = 'default') {
      const limit = Math.max(0, Math.min(this.maxFiles[instanceName], (this.uploaderFiles[instanceName] || []).length));

      this.uploaderFilesToSubmit[instanceName] = [];
      for (let i = 0; i < limit; i++) {
        const file = this.uploaderFiles[instanceName][i];

        if (file.uploading) {
          return false;
        }

        const fileToSubmit = {
          tmp: !file.url,
        };

        if (fileToSubmit.tmp) {
          fileToSubmit.code = file.code;
        } else {
          fileToSubmit.id = file.id;
        }

        this.uploaderFilesToSubmit[instanceName].push(fileToSubmit);
      }

      return true;
    },
  },
};
