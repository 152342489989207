<template>
  <label :for="'uploader-files__' + instance" v-show="!maxReached" class="uploader" :class="['uploader__instance__' + instance]" :ref="'uploader-' + instance">
    <span class="uploader__input">
      <svg class="uploader__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
        <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
      </svg>
      <input ref="uploaderFile" class="uploader__file" type="file" :id="'uploader-files__' + instance" :name="'uploader-files__' + instance + '[]'" @change.prevent="selectFiles" :accept="acceptTypes" multiple>

      <span class="uploader__text">
        <slot>
          <strong>Scegli un file</strong> o <span class="uploader__drop">trascinalo qui</span>.
        </slot>
      </span>
    </span>
  </label>
</template>

<script>

let dragAndDropCapable = null;
const determineDragAndDropCapable = () => {
  if (dragAndDropCapable === null) {
    const div = document.createElement('div');

    dragAndDropCapable = (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }

  return dragAndDropCapable;
};

export default {
  props: {
    disableBase64: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    uploadFile: {
      type: Function,
      default (file) {
        file.uploading = true;
        file.uploadedSize = '0';
        file.updateUploadStatus = (e) => {
          file.uploadedSize = parseInt(e.loaded / e.total * 100);
        };

        this
          .uploadApiEndpoint(file)
          .then(res => Object.assign(file, res.data || {}, {
            uploadCompleted: true,
            remove: () => {
              if (file.errorUploading) {
                return;
              }

              this.removeUploadedFile(file);
            },
          }))
          .catch(() => Object.assign(file, {
            errorUploading: true,
            retryUpload: () => {
              if (file.uploadCompleted) {
                return;
              }

              this.retryUploadFile(file);
            },
          }))
          .finally(() => Object.assign(file, {
            uploading: false,
            uploadedSize: null,
          }))
        ;
      },
    },
    uploadApiEndpoint: {
      type: Function,
      default (file) {
        return new Promise(resolve => resolve());
      },
    },
    max: {
      type: Number,
      default: null,
    },
    allowedFiles: {
      type: Array,
      default: () => [],
    },
    instance: {
      type: String,
      default: 'default',
    },
  },
  data: () => ({
    fileToRemove: null,
  }),
  computed: {
    acceptTypes () {
      if (this.allowedFiles.length <= 0) {
        return null;
      }

      return this.allowedFiles.reduce((a, s) => s + a, '');
    },
    maxReached () {
      if (this.max === null) {
        return false;
      }

      return this.value.length >= this.max;
    },
  },
  methods: {
    openFilePicker () {
      this.$refs.uploaderFile.click();
    },

    dropFiles (e) {
      if (!determineDragAndDropCapable()) {
        return;
      }

      this.uploadFiles(e.dataTransfer.files);
    },
    selectFiles (e) {
      if (e.target.files.length > 0) {
        if (this.fileToRemove) {
          this.removeUploadedFile(this.fileToRemove);
        }

        this.uploadFiles(e.target.files);
        this.fileToRemove = null;
      }
    },
    uploadFiles (files) {
      for (let i = 0; i < files.length; i++) {
        this.upload(files[i]);
      }
    },
    upload (file) {
      if (this.maxReached) {
        return;
      }

      const b64File = {
        content: null,
        name: file.name,
        size: file.size,
        type: file.type,
        modifiedAt: file.lastModified,
        calculating: true,
        uploading: false,
      };
      this.value.push(b64File);

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        if (reader.result.length === 0) {
          return;
        }

        b64File.size = reader.result.length;
        b64File.content = btoa(reader.result);
        b64File.calculating = false;

        this.uploadFile(b64File);
      };
    },

    addFile (file) {
      this.uploadFile(file);
      this.$emit('input', this.value, this.instance);
    },
    retryUploadFile (file) {
      this.addFile(file);
    },
    removeUploadedFile (file) {
      this.$emit('input', this.value.filter(f => f !== file), this.instance);
    },
  },
  mounted () {
    if (!determineDragAndDropCapable()) {
      return;
    }

    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
      this.$refs['uploader-' + this.instance].addEventListener(evt, e => {
        e.preventDefault();
        e.stopPropagation();
      }, false);
    });

    this.$refs['uploader-' + this.instance].addEventListener('drop', this.dropFiles);
  },
};

</script>

<style lang="scss">

.uploader {
  border: 2px dashed dimgrey;
  padding: 15px;
  cursor: pointer;
  margin-right: 15px;

  &__input {
    text-align: center;
  }

  &__icon {
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
  }

  &__text {
  }

  &__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      user-select: none;
    }

    &:hover + label strong {
      color: #39bfd3;
    }
  }
}

</style>
