<template>
  <div class="file-preview" :style="style">
    <div class="file-preview__image" :style="previewImageStyle">
      <div class="image-actions">
        <template v-if="image.errorUploading">
          <p class="image-action" @click.prevent="image.retryUpload">Riprova</p>
        </template>
        <template v-else-if="image.url || image.uploadCompleted || image.uploadedSize === 100">
          <p class="image-action" @click.prevent="remove">Rimuovi</p>
          <p class="image-action" @click.prevent="replace">Sostituisci</p>
        </template>
      </div>
    </div>

    <div class="file-preview__data">
      <strong class="file-preview__name" :title="image.name">
        {{ image.name }}
      </strong>

      <span class="file-preview__size">
        {{ image.size | prettyBytes }}
      </span>

      <p v-if="image.errorUploading" class="file-preview__info file-preview__error">
        Errore nel caricamento.
      </p>
      <p v-else-if="image.uploading || (!image.url && !image.uploadCompleted && 100 !== image.uploadedSize)" class="file-preview__info file-preview__error">
        Caricamento... {{ image.uploadedSize }} %
      </p>
    </div>
  </div>
</template>

<script>

import prettyBytes from '../filters/prettyBytes.js';

export default {
  filters: {
    prettyBytes,
  },
  props: {
    image: {
      type: Object,
      default: () => ({}),
    },
    maxSize: {
      type: Number,
      default: 150,
    },
    instance: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    style () {
      const style = {};

      style.display = 'flex';

      if (this.maxSize) {
        style.width = this.maxSize + 'px';
      }

      return style;
    },
    previewImageStyle () {
      const style = {};

      if (this.image.url) {
        style['background-image'] = 'url(' + this.image.thumbUrl + ')';
      } else {
        style['background-image'] = 'url(data:' + (this.image.mime || 'image/png') + ';base64,' + this.image.content + ')';
      }

      return style;
    },
  },
  methods: {
    remove () {
      this.$emit('remove', this.image, this.instance);
    },
    replace () {
      this.$emit('replace', this.image, this.instance);
    },
  },
};

</script>

<style lang="scss">

.file-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 100%;
  position: relative;

  &__image {
    display: flex;
    justify-content: center;
    background: no-repeat center center;
    background-size: cover;
    position: relative;
    margin-bottom: 15px;

    &::after {
      display: block;
      content: '';
      padding-bottom: 100%;
    }

    .image-actions {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, .8);
      padding: 15px;

      .image-action {
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }

    &:hover .image-actions {
      display: block;
    }
  }

  &__data {
  }

  &__name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__size {
    display: block;
    text-align: center;
  }
}

</style>
